@import "../FixBreakpoints.scss";

.quiz-schedule-wrapper {
    height: 100vh;
    overflow-y: auto;

    .grid {
        display: grid;
        grid-template-columns: 4fr 1fr auto;
        grid-template-rows: 100px 1fr;
        height: 100vh;
        overflow: hidden;

        grid-template-areas:
            "title title player-counter"
            "schedule-table messageList messageList";

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr auto;
            grid-template-rows: 100px 150px 1fr;
            grid-template-areas:
                "title player-counter"
                "messageList messageList"
                "schedule-table schedule-table";
            height: auto;
            //height: 100vh;
        }

        .title-wrap {
            grid-area: title;
            margin-top: 15px;
            margin-left: 15px;

            .title {
                text-transform: uppercase;
                color: white;
                font-weight: bold;
                border-bottom: 3px solid white;
                margin-left: 95px;
                padding-bottom: 7px;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    border-bottom-width: 2px;
                    margin-left: 60px;
                }
            }

            .description {
                color: white;

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    margin-left: 65px;
                }
            }

            .quiz-schedule {
                width: 80px;
                height: 80px;
                background-image: url("/assets/images/icon/schedule_icon.png");
                margin-right: 15px;

                @include media-breakpoint-down(md) {
                    width: 50px;
                    height: 75px;
                }
            }
        }

        .message-list {
            grid-area: messageList;
            margin-bottom: 140px;
            margin-left: 15px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }

        .send-message {
            grid-area: sendMessage;

            .send-message-wrapper {
                z-index: 11;
            }
        }

        .player-counter-wrap {
            grid-area: player-counter;
            margin: 8px;
        }

        .quiz-schedule-table {
            grid-area: schedule-table;
            //overflow: hidden;
            align-self: center;

            @include media-breakpoint-down(md) {
                align-self: center;
            }

            .quiz-box-wrap {
                padding: 15px 10px 0px 20px;
                height: 230px;
            }

            .quiz-screen {
                &.list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 40px;
                    margin-top: 30px;
                    margin-left: 30px;
                    margin-bottom: 30px;

                    @include media-breakpoint-down(md) {
                        grid-template-columns: 1fr;
                        margin-bottom: 120px;
                        margin-right: 30px;
                    }
                }

                &.slider {
                    margin-bottom: 90px;
                    height: 50vh;
                }

                .splide__arrow {
                    top: unset;
                    bottom: -40px;
                    background: transparent;
                    opacity: 1;
                    z-index: 10;

                    svg {
                        fill: white;
                        height: 28px;
                    }
                }

                .splide__pagination {
                    bottom: -10px;
                }

                .quiz-box {
                    background-color: #fff;
                    border-radius: 0.5rem;
                    padding: 0.75rem;
                    box-shadow: 2px 1px 3px 1px #00000038;

                    display: grid;
                    grid-template-columns: 80px 1fr;
                    grid-gap: 8px;

                    .description {
                        font-size: 12px;
                        color: #b8bded;

                        //max-height: 73px;
                        //overflow: auto;
                    }

                    .qf-timer {
                        width: 70px;
                        color: #afb1f1;
                        font-weight: 600;

                        .far {
                            margin-right: 3px;
                        }
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .header {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            border-bottom-width: 2px;
                            border-bottom-color: #b8bded;
                            border-bottom-style: solid;
                            margin-bottom: 10px;

                            .qh-name {
                                font-size: 20px;
                                color: #4248df;
                                font-weight: bold;

                                @include media-breakpoint-down(sm) {
                                    font-size: 15px;
                                }
                            }

                            .qh-price {
                                font-size: 16px;
                                color: #4248df;
                                font-weight: 600;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .fas {
                                    margin-right: 3px;
                                }
                            }
                        }
                    }

                    .footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .btn.btn-play {
                        background-color: #ffdc00;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 30px;
                        color: #ff8f2a;
                        padding: 4px 4px;
                        max-width: 200px;
                        min-width: 140px;

                        .play-text {
                            font-weight: bold;
                            margin-right: 10px;
                            text-transform: uppercase;
                        }

                        i.fas.fa-pound-sign {
                            background: #ff8f2a;
                            color: #ffdc00;
                            padding: 3px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                        }
                    }

                    .icon {
                        position: relative;
                        top: -25px;
                        left: -25px;
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 13px;
                        box-shadow: 2px 1px 3px 2px #00000038;
                    }

                    .icon-music {
                        background-image: url("/assets/images/icon/music_lobbyicon.png");
                    }

                    .icon-hollywood {
                        background-image: url("/assets/images/icon/hollywood_lobbyicon.png");
                    }

                    .icon-00s {
                        background-image: url("/assets/images/icon/00s_lobbyicon.png");
                    }

                    .icon-80s {
                        background-image: url("/assets/images/icon/80s_lobbyicon.png");
                    }

                    .icon-90s {
                        background-image: url("/assets/images/icon/90s_lobbyicon.png");
                    }
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        color: white;
        z-index: 99;
        width: 181px;
        height: 49px;
        top: auto;
        bottom: -58px;

        &.next-arrow {
            right: 0;
        }

        &.prev-arrow {
            left: 0;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        opacity: 1;
        font-size: 30px;
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            width: 7px;
            height: 7px;
            background-color: white;
            border-radius: 50%;
            list-style: none;
            margin: 5px;

            &.slick-active {
                transform: scale(1.5);
            }

            button {
                display: none;
            }
        }
    }
}

.quiz-screen {
    .swiper-container-vertical > .swiper-pagination-bullets {
        top: 25%;
        display: flex;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 120px;
    }

    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 5px;
    }

    .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: 25%;
        color: white;
        font-size: 25px;
        font-weight: bold;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 55px;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 55px;
    }

    .swiper-pagination-bullet-active {
        background: white;
    }
}
