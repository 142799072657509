/* -----------------------------------------------------
   created with https://www.codeandweb.com/texturepacker 
   -----------------------------------------------------
   $TexturePacker:SmartUpdate:20fd00a9f71a02ec313b03a68c327830:8b20d793f673e4366d06e3ed695d42c1:98343af855bb0a80be8a62653f0533b2$
   -----------------------------------------------------

   usage: <span class="{-spritename-} sprite"></span>

   replace {-spritename-} with the sprite you like to use

*/
.music_00000 {width:972px; height:260px; background-position: -0px -0px}
.music_00001 {width:972px; height:260px; background-position: -972px -0px}
.music_00002 {width:972px; height:260px; background-position: -0px -260px}
.music_00003 {width:972px; height:260px; background-position: -972px -260px}
.music_00004 {width:972px; height:260px; background-position: -0px -520px}
.music_00005 {width:972px; height:260px; background-position: -972px -520px}
.music_00006 {width:972px; height:260px; background-position: -0px -780px}
.music_00007 {width:972px; height:260px; background-position: -972px -780px}
.music_00008 {width:972px; height:260px; background-position: -0px -1040px}
.music_00009 {width:972px; height:260px; background-position: -972px -1040px}
.music_00010 {width:972px; height:260px; background-position: -0px -1300px}
.music_00011 {width:972px; height:260px; background-position: -972px -1300px}
.music_00012 {width:972px; height:260px; background-position: -0px -1560px}
