.background-color {
    &.nschedule {
        background: linear-gradient(to bottom, #2026bb, #383dc2);
    }

    &.n80s {
        background: linear-gradient(to bottom, #2e6ef6, #5d12eb, #5d12eb);
    }

    &.n90s {
        background: linear-gradient(to bottom, #03d5eb, #00edd4, #00edd4);
    }

    &.n00s {
        background: linear-gradient(to bottom, #c00d52, #ea1c7a);
    }

    &.nmusic {
        background: linear-gradient(to bottom, #ff9a2a, #ffce2a, #ffce2a);
    }

    &.nhollywood {
        background: linear-gradient(to bottom, #fe551e, #f3d138, #f3d138);
    }

    min-height: 100vh;

    .icons {
        background-image: url("/assets/images/quiz_backgroundwhite_icon.png");
        position: absolute;
        pointer-events: none;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0.1;
        -webkit-mask-image: -webkit-gradient(
            linear,
            center bottom,
            center top,
            color-stop(0, transparent),
            color-stop(1, #000)
        );
        height: 50vh;
    }
}
