/* -----------------------------------------------------
   created with https://www.codeandweb.com/texturepacker 
   -----------------------------------------------------
   $TexturePacker:SmartUpdate:971c8f631f9eccd34ed4a5cd26850953:47d20adfafe9838e3bbbafd589add892:2aad72657bd794d3a70f65af547055d9$
   -----------------------------------------------------

   usage: <span class="{-spritename-} sprite"></span>

   replace {-spritename-} with the sprite you like to use

*/

.n80s_00001 {
    width: 972px;
    height: 260px;
    background-position: -0px -0px
}

.n80s_00002 {
    width: 972px;
    height: 260px;
    background-position: -972px -0px
}

.n80s_00003 {
    width: 972px;
    height: 260px;
    background-position: -0px -260px
}

.n80s_00004 {
    width: 972px;
    height: 260px;
    background-position: -972px -260px
}

.n80s_00005 {
    width: 972px;
    height: 260px;
    background-position: -0px -520px
}

.n80s_00006 {
    width: 972px;
    height: 260px;
    background-position: -972px -520px
}

.n80s_00007 {
    width: 972px;
    height: 260px;
    background-position: -0px -780px
}

.n80s_00008 {
    width: 972px;
    height: 260px;
    background-position: -972px -780px
}

.n80s_00009 {
    width: 972px;
    height: 260px;
    background-position: -0px -1040px
}

.n80s_00010 {
    width: 972px;
    height: 260px;
    background-position: -972px -1040px
}

.n80s_00011 {
    width: 972px;
    height: 260px;
    background-position: -0px -1300px
}

.n80s_00012 {
    width: 972px;
    height: 260px;
    background-position: -972px -1300px
}
