@import "../../pages/FixBreakpoints.scss";
:root {
  --animate-delay: 0.5s;
}

.placement-wrap {
  .active .player-name {
    margin-top: 2px;
  }
  .player-name {
    margin-top: 13px;
    // display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: "Alfa Slab One", cursive;
    letter-spacing: 1px;

    @include media-breakpoint-down(md) {
      margin-top: 5px !important;
      overflow: visible;
      margin: 0 auto;
      text-align: center;
    }
  }

  @include media-breakpoint-down(md) {
    align-self: flex-start;
    margin-left: -45px;
  }
}

.ui_firstplace {
  width: 60px;
  height: 60px;
  margin-top: -10px;
  background-image: url("/assets/images/crown_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @include media-breakpoint-down(md) {
    // width: 55px !important;
    // height: 55px !important;
    margin-bottom: 0;
  }

  &.secondp {
    background-image: url("/assets/images/crown_2.png");
    width: 55px;
    height: 55px;
  }

  &.thirdp {
    background-image: url("/assets/images/crown_3.png");
    width: 55px;
    height: 55px;
  }
}

.player-placements {
  flex-direction: row;
  padding: 0;
  text-align: center;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  z-index: 5;
  border-radius: 50px;
  height: 50px;
  overflow: visible;
  width: 185px;
  background: white;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    height: auto;
    background: transparent;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 63px;
    padding-top: 50px;
  }

  .profile-user {
    margin: 15px 10px 0;

    @include media-breakpoint-down(md) {
      opacity: 0.4;

      &.active {
        opacity: 1;
      }
    }
  }

  .game-position {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    background-color: #a2a5ff;
    color: white;
    font-weight: bold;
    font-family: "Alfa Slab One", cursive;

    @include media-breakpoint-down(md) {
      background-color: #fdfdfd;

      &.n80s {
        color: #3765f3;
      }

      &.n00s {
        color: #a10f52;
      }

      &.n90s {
        color: #03d8e8;
      }

      &.nmusic {
        color: #ffa72a;
      }

      &.nhollywood {
        color: #fc7024;
      }
    }
  }

  .active {
    &.profile-user {
      // padding: 10px 8px;
      // border-top-left-radius: 30px;
      // border-top-right-radius: 30px;
      // margin-top: 5px;

      .number {
        width: 40px;
        height: 41px;
        padding: 6px 8px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin: -6px auto 0 auto;

        @include media-breakpoint-down(md) {
          width: auto;
          height: 42px;

          // height: auto;
        }

        &.n80s {
          background-color: #3765f3;
        }

        &.n00s {
          background-color: #c30f55;
        }

        &.n90s {
          background-color: #03d8e8;
        }

        &.nmusic {
          background-color: #ffa22b;
        }

        &.nhollywood {
          background-color: #fd6522;
        }

        @include media-breakpoint-down(md) {
          background-color: transparent !important;
        }
      }

      @include media-breakpoint-down(md) {
        background: transparent !important;
      }

      .game-position {
        background-color: #ffffff;
        border-radius: 35px;

        @include media-breakpoint-down(md) {
          font-size: 20px;
        }

        &.n80s {
          color: #3765f3;
        }

        &.n00s {
          color: #c30f55;
        }

        &.n90s {
          color: #05bfff;
        }

        &.nmusic {
          color: #ffa22b;
        }

        &.nhollywood {
          color: #fd6522;
        }

        @include media-breakpoint-down(md) {
          width: 40px;
          height: 40px;
        }
      }

      .player-name {
        color: white;
        font-weight: normal;
      }

      .player-score {
        color: white;
        // font-weight: bold;
      }
    }
  }
}
.active-div
{
    display: flex;
    flex-direction: row;
    max-width: 185px;
    justify-content: flex-start;

    @include media-breakpoint-down(md) {
      height: 260px;
      flex-direction: column;
    }
}

.scroll-div {
  display: flex;
  flex-direction: row;
  max-width: 185px;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f13b; 
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888888ad; 
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  @include media-breakpoint-down(md) {
    height: 260px;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
  }
}
.game-position {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-name {
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px;
  color: white;
  font-weight: normal;
  font-family: "Alfa Slab One", cursive;
  letter-spacing: 1px;

  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-score {
  text-align: center;
  color: white;
  line-height: 10px;
  font-size: 12px;
  font-family: "Alfa Slab One", cursive;
  @include media-breakpoint-down(md) {
  }
}

.top-players {
  background: white;
  border-radius: 40px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  max-width: 240px;

  @media screen and (min-width:360px) {
    min-width: 200px;
  }

  .profile-user {
    margin-top: 35px;
    margin-right: 7px;
    margin-left: 7px;

    @media screen and (min-width:360px) {
      width: 35px;
      margin-top: 45px;
    }
  }

  img {
    width: 50px;
    margin-top: -8px;
    margin-bottom: 0px;
  }

  .third {
    border-radius: 50%;
    background: #ff6803;

    width: 25px;
    height: 25px;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .second {
    border-radius: 50%;
    background: #a1a5ff;

    width: 25px;
    height: 25px;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
