/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  /* If you want to prevent clicks outside the modal, keep pointer-events: none;
     but then you'll need pointer-events: auto; on .modal-bonus so the user can 
     still click inside. */
  pointer-events: none;
}

/* Modal Container (renamed from .modal to .modal-bonus) */
.modal-bonus {
  position: relative; /* allows absolute positioning inside for the buttons */
  background: #000000d4;
  border-radius: 8px;
  box-shadow: 0 5px 15px #0000004d;
  padding: 20px;
  width: 600px;
  height: 400px;
  z-index: 9;
  display: flex;
  align-items: center;

  /* Allow clicking inside */
  pointer-events: auto;
}

/* Modal Content (renamed from .modal-content to .modal-content-bonus) */
.modal-content-bonus {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Space at bottom so button won’t overlap text */
  padding-bottom: 80px;

  /* Header styling (similar to .modal-header) */
  .modal-header {
    color: white;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 10px;
  }

  /* Body text styling (similar to .modal-body) */
  .modal-body {
    color: white;
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.4;
  }
}

/* Buttons Container (keeps the old absolute positioning) */
.modal-buttons {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
}

/* Continue Button (from original .continue-button) */
.continue-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px !important;
  height: 39px !important;
  background-image: url("../../assets/images/quiz_upsell.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin: 0 auto;

  .text {
    color: white;
    font-weight: 800;
    font-size: 20px;
    padding-right: 50px;
  }
}
