@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import "./FixBreakpoints.scss";

@font-face {
  font-family: "Alfa Slab One";
  src: url("/assets/fonts/AlfaSlabOne-Regular.ttf") format("truetype");
}

.loading-icon {
  font-size: 35px;
  color: #2026bb;
}

.Toastify__toast--default {
  border-radius: 5px;
  color: #1f3fb8;
  font-weight: bold;
  font-size: 14px;
  // text-transform: uppercase;
}

.connection-id {
  position: fixed;
  bottom: 0px;
  left: 5px;
  font-size: 12px;
  color: white;
  opacity: 0.4;
}

.exit-quiz-button {
  background-color: #fedc25;
  border: none;
  color: #fe9230;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 8px;
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bolder;
  line-height: 18px;
  display: none;

  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

.already-in-button {
  background: white;
  border: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
  padding: 15px;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // font-family: 'Alfa Slab One', cursive;
  font-family: "Source Sans Pro", sans-serif;
}

.grecaptcha-badge {
  width: 69px !important;
  height: 60px;
  display: block;
  transition: right 0.3s ease 0s;
  position: fixed;
  bottom: 20px !important;
  left: 0px;
  right: initial;
  box-shadow: grey 0px 0px 5px;
  border-radius: 2px;
  overflow: hidden;

  &:hover {
    width: 241px !important;
  }
}

.toastr-wrap {
  background-color: #fefefe;
  color: #f1692d;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 97vw;
    padding: 10px;
    left: 5px;
    margin: 5px 0;
  }
}

.Toastify__progress-bar--default {
  background: #1f3fb8;
  opacity: 0.5 !important;
}
.Toastify__close-button--default {
  color: #1f3fb8;
  opacity: 1;
  top: 5px;
  position: relative;
}

body {
  margin: 0;
}
.row {
  clear: both;
}
img {
  max-width: 100%;
}

*:focus {
  outline: none;
  box-shadow: none;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.fix-wrapper-padding {
  margin: 0 -15px;
}
input[type="text"],
input[type="number"],
textarea {
  font-size: 16px !important;
}
.disconnect-msg {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #3e3e3ee3;
  color: white;
  font-weight: bold;
  position: fixed;
  padding: 15px;
  left: 0;
  right: 0;
  border-radius: 25px;
  top: 24%;
  z-index: 9999;
  max-width: 500px;
  margin: 0 auto;
  min-height: 300px;
  border: 5px solid black;

  .reload-btn,
  .exit {
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    min-width: 145px;
    box-shadow: none;
    border: none;
    font-weight: bold;
  }

  .reload-btn {
    background: #ffffff;
    color: black;
  }

  .exit {
    background: #ffffff;
    margin: 0;
    color: black;
    display: none;

    @include media-breakpoint-down(md) {
      display: inline-block;
    }
  }
}

.icon {
  width: 19px;
  height: 19px;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &.lobby {
    background-image: url("/assets/images/icon/playercount.png");
  }

  &.i-question {
    background-image: url("/assets/images/icon/question_icon.png");
  }

  &.lg {
    width: 50px;
    height: 50px;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.Toastify__toast--error {
  background: #e74c3c;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px;
  width: 327px;
  color: white !important;
}

.Toastify__toast--warning {
  background: #ffdc01;
  color: #ff6b00;
  font-weight: bold;
}

.Toastify__toast--info {
  background: #00bcd4;
  font-weight: bold;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
}

.rotate-dev-msg {
  display: none;
}
@media screen and (orientation: landscape) {
  .rotate-dev-msg {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #606060f7;
    color: white;
    z-index: 9991;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
