@import "../../pages/FixBreakpoints.scss";

.send-message-wrapper {
  position: fixed;
  bottom: 0;
  right: 3px;
  z-index: 4;

  @include media-breakpoint-down(sm) {
    z-index: 5;
  }

  @include media-breakpoint-up(lg) {
    bottom: 3px;
    .open-chat-button {
      display: none;
    }
  }

  &.open {
    bottom: 3px;
    z-index: 99;
  }

  .send-button {
    border-radius: 50%;

    &.nschedule {
      background: #4248df;
    }
    &.n80s {
      background: #4f2fee;
    }
    &.n90s {
      background: #03d5eb; // #00EDD4;
    }
    &.n00s {
      background: #c00d52; // #EA1C7A;
    }
    &.nmusic {
      background: #ff9a2a; // #FFCE2A;
    }
    &.nhollywood {
      background: #fe551e; // #F3D138;
    }

    font-size: 1rem;
    padding: 0;
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .send-message-ui {
    margin-right: 70px;
    width: 100%;
    display: none;
    grid-template-columns: 1fr 54px;
    justify-content: center;
    background: #cacaca63;
    align-items: center;
    border-radius: 5px;

    @include media-breakpoint-down(md) {
      box-shadow: 0px 0px 5px 0px #737373;
      background-color: white;
    }

    @include media-breakpoint-down(lg) {
      &.open {
        display: grid;
      }
    }

    input {
      padding: 10px;
      border: none;
      background-color: #ffffff42;
      color: #6b6b6b;
      margin: 10px;
      border-radius: 5px;
      text-align: left;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      text-align: right;

      &:focus {
        background-color: white;
        box-shadow: 0px 0px 3px #cecece;
      }

      &::placeholder {
        text-align: right;
      }

      &.nschedule {
        &::placeholder {
          color: #4248df;
        }
        color: #4248df;
      }
      &.n80s {
        &::placeholder {
          color: #4f2fee;
        }
        color: #4f2fee;
      }
      &.n90s {
        &::placeholder {
          color: #03d5eb;
        }
        color: #03d5eb;
      }
      &.n00s {
        &::placeholder {
          color: #c00d52;
        }
        color: #c00d52;
      }
      &.nmusic {
        &::placeholder {
          color: #ff9a2a;
        }
        color: #ff9a2a;
      }
      &.nhollywood {
        &::placeholder {
          color: #fe551e;
        }
        color: #fe551e;
      }
    }

    @include media-breakpoint-up(lg) {
      background: transparent;
      display: grid;
    }
  }

  .open-chat-button {
    padding: 11px;
    margin-right: 10px;
    color: white;
    font-size: 27px;
    float: right;
    height: 57px;
    width: 57px;
    border-radius: 30px 30px 0 0;
    border: 3px solid white;

    &.nschedule {
      background: #4248df;
    }
    &.n80s {
      background: #4f2fee;
    }
    &.n90s {
      background: #03d5eb; // #00EDD4;
    }
    &.n00s {
      background: #c00d52; // #EA1C7A;
    }
    &.nmusic {
      background: #ff9a2a; // #FFCE2A;
    }
    &.nhollywood {
      background: #fe551e; // #F3D138;
    }
  }
}

.chat-text-area {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 45%,
    left top,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  min-height: 150px;
  overflow: auto;
  z-index: 3;

  @include media-breakpoint-down(md) {
    height: calc(100vh - 613px);
  }

  .message-body {
    background: white;
    padding: 4px 15px;
    word-break: break-all;
    width: auto;
    display: inline-block;
    border-radius: 20px 20px 7px 20px;
    color: #a3a6ef;
    font-weight: 600;
    margin-top: 0;
    font-size: 14px;
    min-width: 75px;
  }

  .name {
    border-radius: 0.7rem;
    background-color: #de136b;
    float: right;
    text-align: center;
    clear: both;
    color: white;
    font-size: 10px;
    margin-top: -3px;
    margin-right: -5px;
    padding: 1px 7px;
  }

  .message-text {
    display: inline-block;
    max-width: calc(100vw / 2 - 165px);
    padding-right: 10px;
    margin-top: 10px;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}
