/* -----------------------------------------------------
   created with https://www.codeandweb.com/texturepacker 
   -----------------------------------------------------
   $TexturePacker:SmartUpdate:df26642d1ccb5dc914b541c6544ce39a:b6fd9bcf08fd7301df1befeffb02d243:98343af855bb0a80be8a62653f0533b2$
   -----------------------------------------------------

   usage: <span class="{-spritename-} sprite"></span>

   replace {-spritename-} with the sprite you like to use

*/


.hollywood_00001 {width:972px; height:260px; background-position: -0px -0px}
.hollywood_00002 {width:972px; height:260px; background-position: -972px -0px}
.hollywood_00003 {width:972px; height:260px; background-position: -0px -260px}
.hollywood_00004 {width:972px; height:260px; background-position: -972px -260px}
.hollywood_00005 {width:972px; height:260px; background-position: -0px -520px}
.hollywood_00006 {width:972px; height:260px; background-position: -972px -520px}
.hollywood_00007 {width:972px; height:260px; background-position: -0px -780px}
.hollywood_00008 {width:972px; height:260px; background-position: -972px -780px}
.hollywood_00009 {width:972px; height:260px; background-position: -0px -1040px}
.hollywood_00010 {width:972px; height:260px; background-position: -972px -1040px}
.hollywood_00011 {width:972px; height:260px; background-position: -0px -1300px}
