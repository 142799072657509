.connection-message-wrapper {
    position: absolute;
    z-index: 999;
    display: table;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: rgba(128, 128, 128, 0.61);
}

.connection-message-wrapper > div {
    display: table-cell;
    vertical-align: middle;
}

.notice-message-wrapper {
    background: #d80e0e;
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.no-overflow {
    overflow: hidden;
}
