@import "../../pages/FixBreakpoints.scss";

.player-counter {
    .number {
        color: white;
        font-size: 24px;
        font-weight: bold;
        margin-left: 5px;
        margin-bottom: 0;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    .price {
        color: white;
        font-size: 17px;
        font-weight: bold;
        margin-left: 5px;
        margin-bottom: 0;
        border: 1px solid #ffffff5c;
        border-radius: 34px;
        padding: 0 10px 0 30px;
        margin-left: -25px;
        border-left: none;
        margin-top: 8px;

        @include media-breakpoint-up(lg) {
            font-size: 25px;
        }
    }
    .settings-icon {
        display: block;
        color: white;
        margin-left: 9px;
        font-size: 20px;

        @include media-breakpoint-up(lg) {
            margin-left: 15px;
            font-size: 25px;
        }
    }
    .light {
        opacity: 0.5;
    }
}

.available-money-wrap {
    .fas {
        font-size: 20px;
        margin-right: 3px;
        margin-left: 10px;

        // @include media-breakpoint-down(xs) {
        //     margin-left: 0;
        // }
    }
    span {
        font-size: 20px;
        font-weight: bold;
    }
}
