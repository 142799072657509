/* -----------------------------------------------------
   created with https://www.codeandweb.com/texturepacker 
   -----------------------------------------------------
   $TexturePacker:SmartUpdate:b67115b7bb15dc4eb0ee5d18ab3f65f8:fdbfd869712af705eb0603c3b334b39b:98343af855bb0a80be8a62653f0533b2$
   -----------------------------------------------------

   usage: <span class="{-spritename-} sprite"></span>

   replace {-spritename-} with the sprite you like to use

*/

.n00s_00001 {
    width: 972px;
    height: 260px;
    background-position: -0px -0px
}

.n00s_00002 {
    width: 972px;
    height: 260px;
    background-position: -972px -0px
}

.n00s_00003 {
    width: 972px;
    height: 260px;
    background-position: -0px -260px
}

.n00s_00004 {
    width: 972px;
    height: 260px;
    background-position: -972px -260px
}

.n00s_00005 {
    width: 972px;
    height: 260px;
    background-position: -0px -520px
}

.n00s_00006 {
    width: 972px;
    height: 260px;
    background-position: -972px -520px
}

.n00s_00007 {
    width: 972px;
    height: 260px;
    background-position: -0px -780px
}

.n00s_00008 {
    width: 972px;
    height: 260px;
    background-position: -972px -780px
}

.n00s_00009 {
    width: 972px;
    height: 260px;
    background-position: -0px -1040px
}

.n00s_00010 {
    width: 972px;
    height: 260px;
    background-position: -972px -1040px
}

.n00s_00011 {
    width: 972px;
    height: 260px;
    background-position: -0px -1300px
}

.n00s_00012 {
    width: 972px;
    height: 260px;
    background-position: -972px -1300px
}

.n00s_00013 {
    width: 972px;
    height: 260px;
    background-position: -0px -1560px
}
