@import "../../pages/FixBreakpoints.scss";

.intro-animation-wrap {
    transform: scale(0.8);
    left: -170px;
    right: 0;
    position: absolute;
    z-index: 0;
    top: -235px;

    @include media-breakpoint-down(md) {
        transform: scale(0.8);
        left: -83px;
        right: 0;
        position: absolute;
        z-index: 0;
        top: -275px;
    }

    @include media-breakpoint-down(sm) {
        transform: scale(0.5);
        left: -122px;
        right: 0;
        position: absolute;
        z-index: 0;
        top: -220px;
    }

    // @include media-breakpoint-down(xs) {
    //     transform: scale(0.3);
    //     left: -122px;
    //     right: 0;
    //     position: absolute;
    //     z-index: 0;
    //     top: -220px;
    // }
}

.quiz-wood {
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;

    &.n80s {
        background-image: url("./theme_intro_animations/80s/80s.png");
    }

    &.n00s {
        background-image: url("./theme_intro_animations/00s/00s.png");
    }

    &.n90s {
        background-image: url("./theme_intro_animations/90s/90s.png");
    }

    &.nmusic {
        background-image: url("./theme_intro_animations/music/music.png");
    }

    &.nhollywood {
        background-image: url("./theme_intro_animations/hollywood/hollywood.png");
    }
}
