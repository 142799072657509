@import "../FixBreakpoints.scss";

.entry-screen {
  display: grid;
  overflow: hidden;

  .game-info {
    background: white;
    padding: 25px 25px 0px 25px;
    border-radius: 20px;
    position: absolute;
    right: 30px;
    max-width: 615px;
    top: 50px;
    right: 30px;
    margin-left: 10px;
    border: 8px solid #02dde447;
    z-index: 5;
    max-height: 540px;
    overflow-y: scroll;

    @include media-breakpoint-down(md) {
      overflow-y: auto;
      max-height: 600px;

    }

    .title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0 0 0 20px;
      font-size: 15px;
      overflow: auto;
      text-align: justify;
      list-style-type: disc;

      li {
        margin-bottom: 10px;
      }
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 5px;
      font-size: 25px;
      cursor: pointer;
      color: #02dde4;
    }
  }


  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  /* Modal Container */
  .modal-pop {
    background: #000000d4;
    border-radius: 8px;
    box-shadow: 0 5px 15px #0000004d;
    padding: 20px;
    width: 500px;
    height: 300px;
    z-index: 9;
    left: auto;
    top: auto;
    display: flex;
    align-items: center;
  }

  /* Modal Content */
  .modal-content-pop {
    .text {
      color: white;
      font-weight: 800;
      font-size: 24px;
    }
  }

  .modal-buttons {
    display: flex;
    margin-top: 20px;
    flex-wrap: nowrap;
    justify-content: space-around;

    .text-btn {
      color: white;
      font-weight: 800;
      margin: 25px;
    }
  }


.info-icon {
  .fas {
    color: #ffffffbd;
    padding: 5px;
    margin-right: 0;
    border: 2px solid #ffffffad;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }
}

.chat-text-area {
  max-height: calc(100vh - 130px);
  position: absolute;
  margin-top: 40px;
  // pointer-events: none;
  display: flex;
  align-items: flex-end;
}

.background-color {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.curve-white-top {
  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }

  .images {
    height: 95px;
  }
}

.server-notice {
  background: rgb(52, 44, 121);
  background: linear-gradient(90deg, rgba(52, 44, 121, 0.59) 0%, rgba(52, 44, 121, 1) 50%, rgba(52, 44, 121, 0.5919411709996498) 100%);
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 25px;

  .text {
    font-size: 16px;
    font-weight: bold;
  }
}

.play-area {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 20px;

  .description {
    font-size: 10px;
    color: #c0c2ec;
    text-align: justify;
    margin-bottom: 10px;
  }

  .back-button {
    font-size: 28px;
    margin-bottom: 14px;
    color: #2026bb;
    padding: 5px;
    width: 30px;
  }

  .count-area {
    height: 100px;

    .number.price {
      text-align: center;
      font-size: 22px;
      color: #2a30be;
      font-weight: bolder;

      span {
        margin-right: 10px;
      }
    }

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      margin-top: 5px;
      color: #2026bb;
      font-size: 20px;
      text-decoration: underline;
      letter-spacing: 1.3px;
    }
  }

  .start-time-text {
    color: #777777;
    float: right;

    .text {
      float: left;
      font-size: 10px;
      margin-top: 2px;
      margin-right: 3px;
    }

    span {
      font-size: 12px;
      float: right;
      min-width: 50px;
    }
  }

  .count-wrap {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;

    .number.price {
      color: #2026bb;
      font-weight: 600;
      font-size: 18px;

      .fa {
        margin-right: 5px;
      }
    }

    .name {
      color: #2026bb;
      font-weight: bolder;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .intro-text {
    border-bottom: 1px solid gray;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #2026bb;
  }

  .wrapper {
    position: relative;

    &::after {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 4px solid #ffdc00;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }

    &:hover::after,
    &:focus::after {
      animation: none;
      display: none;
    }

    @keyframes ring {
      0% {
        width: 30px;
        height: 30px;
        opacity: 1;
      }

      100% {
        width: 300px;
        height: 300px;
        opacity: 0;
      }
    }

    &.hide {
      &::after {
        content: "";
        border: 0px solid #ffdc00;
      }
    }
  }

  .play-button-hide {
    position: relative;
    color: white;
    background: grey !important;
    border-radius: 30px;
    padding: 5px;
    min-width: 188px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    .gray-text {
      font-size: 11px;
      float: right;
      text-align: center;
      color: #363636;
      padding-right: 3px;
    }

    .text {
      font-weight: 600;
      text-transform: uppercase;
    }

    .price {
      font-weight: 600;
      color: white;
    }

    .circle {
      background: white;
      padding: 4px;
      border-radius: 50%;
      color: black;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .play-button {
    position: relative;
    color: #ff6b00;
    background: #ffdc00;
    border-radius: 30px;
    padding: 5px;
    min-width: 188px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: 10px;
      z-index: 3;
    }

    .gray-text {
      font-size: 11px;
      float: right;
      text-align: center;
      color: #363636;
      padding-right: 3px;
    }

    .text {
      font-weight: 600;
      text-transform: uppercase;
    }

    .price {
      font-weight: 600;
      color: #ff8e2a;
    }

    .circle {
      background: #ff8e2a;
      padding: 4px;
      border-radius: 50%;
      color: #ffdc00;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .play-button::before {
    content: "";
    min-width: calc(190px + 12px);
    min-height: calc(38px + 12px);
    border-radius: 1000px;
    border: 4px solid #ffdc00;
    box-shadow: 0 0 60px #ffdc00;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }

  .play-button:hover,
  .play-button:focus {
    color: #ff8e2a;
    transform: translateY(-6px);
  }

  .play-button:hover::before,
  .play-button:focus::before {
    opacity: 1;
  }
}

.images {
  display: flex;
  justify-content: center;

  .count {
    position: absolute;
    left: 0;
    text-align: center;
    right: -5px;
    font-weight: bold;
    color: white;
    font-size: 60px;
    bottom: 86px;
  }
}

.character {
  width: 300px;
  margin-top: -200px;
  position: relative;

  @include media-breakpoint-down(md) {
    width: 250px;
    margin-top: -195px;
    position: relative;
    height: 250px;
    // background: white;
    padding: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0px 0px 10px #00000042;
  }
}

.character-clock-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.character-clock {
  width: 261px;
  margin-top: -309px;
  position: relative;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;

  // --animate-duration: 15s;
}

.shadow {
  animation-name: example2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  width: 300px;
}
}

@keyframes example2 {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  75% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes example {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 10px, 0);
  }

  50% {
    transform: translate3d(0, 0px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  100% {
    transform: translate3d(0, 0px, 0);
  }
}