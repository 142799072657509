@import "../FixBreakpoints.scss";

.night {
    position: absolute;
    pointer-events: none;
    top: 50px;
    left: 0px;
    right: 0px;
    height: calc(100vh - 500px);

    @include media-breakpoint-up(md) {
        height: 200px;
        top: 0px;
    }
}

.shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    overflow: visible;

    &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 20px;
        width: 20px;

        background: rgb(255, 255, 255);
        background: radial-gradient(
            circle,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 3%,
            rgba(255, 255, 255, 0) 10%
        );

        animation: shining 5000ms ease-in-out infinite;
        border-radius: 50%;
    }

    // @for $i from 1 through 13 {
    //   &:nth-child(#{$i}) {
    //     top: #{random(100%) - 0%};
    //     left: #{random(100%) + 0%};
    //     opacity: #{random(1) - 0.3};
    //     margin: 0 auto;

    //     $delay: random(9999) + 0ms;
    //     animation-delay: $delay;
    //   }
    // }

    &:nth-child(1) {
        top: 5%;
        left: 2%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 9971ms;
        animation-delay: 9971ms;
    }
    &:nth-child(2) {
        top: 75%;
        left: 36%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 4420ms;
        animation-delay: 4420ms;
    }
    &:nth-child(3) {
        top: 0%;
        left: 15%;
        opacity: 1;
        margin: 0 auto;
        -webkit-animation-delay: 3712ms;
        animation-delay: 3712ms;
    }
    &:nth-child(4) {
        top: 48%;
        left: 54%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 3770ms;
        animation-delay: 3770ms;
    }
    &:nth-child(5) {
        top: 43%;
        left: 15%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 8309ms;
        animation-delay: 8309ms;
    }
    &:nth-child(6) {
        top: 85%;
        left: 6%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 6791ms;
        animation-delay: 6791ms;
    }
    &:nth-child(7) {
        top: 78%;
        left: 79%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 353ms;
        animation-delay: 353ms;
    }
    &:nth-child(8) {
        top: 57%;
        left: 20%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 7909ms;
        animation-delay: 7909ms;
    }
    &:nth-child(9) {
        top: 34%;
        left: 64%;
        opacity: 1;
        margin: 0 auto;
        -webkit-animation-delay: 5007ms;
        animation-delay: 5007ms;
    }
    &:nth-child(10) {
        top: 92%;
        left: 96%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 6640ms;
        animation-delay: 6640ms;
    }
    &:nth-child(11) {
        top: 9%;
        left: 27%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 3351ms;
        animation-delay: 3351ms;
    }
    &:nth-child(12) {
        top: 0;
        left: 94%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 6059ms;
        animation-delay: 6059ms;
    }
    &:nth-child(13) {
        top: 71%;
        left: 65%;
        opacity: 0.7;
        margin: 0 auto;
        -webkit-animation-delay: 9469ms;
        animation-delay: 9469ms;
    }
}

@keyframes shining {
    0% {
        width: 30px;
        height: 30px;
        margin-top: 0px;
    }

    50% {
        width: 40px;
        height: 40px;
        margin-top: 5px;
    }

    100% {
        width: 30px;
        height: 30px;

        margin-top: 0px;
    }
}
