@import "../FixBreakpoints.scss";

.game {
  .loading-g {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 42;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #ffffff2b;
  }

  .loading-icon-end {
    font-size: 21px;
    color: #ffffff;
    margin-top: 12px;

    @include media-breakpoint-down(md) {
      margin-top: 16px;
      color: black;
    }
  }

  .cal-text {
    @include media-breakpoint-down(md) {
      // text-shadow: 0px 0px 3px black;
      color: black;
    }
  }
}

.game-screen-wrap {
  padding-top: 10px;

  display: grid;
  min-height: calc(100vh + 0px);

  grid-template-columns: 35px auto 1fr 1.5fr 50px 1fr;
  grid-template-rows: 90px 1fr 50px;

  column-gap: 10px;
  align-items: center;

  grid-template-areas:
    "gift-icon jackpot top-players placement-wrap placement-wrap player-counter-wrap"
    ". question-wrap question-wrap question-wrap . message-list"
    "send-message send-message send-message send-message send-message send-message";

  @include media-breakpoint-down(md) {
    grid-template-columns: 30px 50px 1fr auto;
    grid-template-rows: 50px 1fr;

    grid-template-areas:
      "gift-icon jackpot top-players player-counter-wrap"
      "placement-wrap placement-wrap message-list message-list"
      "question-wrap question-wrap question-wrap question-wrap";

    overflow-x: hidden;
    overflow-y: auto;
  }

  .message-list {
    max-height: calc(100vh - 223px);
  }

  .spinner-wrap {
    position: absolute;
    right: 6px;
    top: -3px;
    z-index: 15;
    pointer-events: none;

    @include media-breakpoint-down(md) {
      right: 2px;
      top: -33px;
    }
  }

  .gift-icon {
    grid-area: gift-icon;

    color: white;
    text-align: center;
    font-size: 25px;
    justify-self: right;
    margin-top: 10px;
    align-self: flex-start;

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
    align-self: flex-start;
  }

  .jackpot {
    grid-area: jackpot;
    color: white;
    // text-align: center;
    font-size: 20px;
    font-weight: bold;
    align-self: flex-start;
    z-index: 5;

    .title {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 13px;
      display: inline-block;
      letter-spacing: 1px;
      text-align: justify;
    }

    .amount {
      margin-top: -10px;
      display: block;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
    align-self: flex-start;
  }

  .top-players {
    grid-area: top-players;
    justify-self: center;
    z-index: 2;
    align-self: flex-start;
  }

  .placement-wrap {
    grid-area: placement-wrap;
    z-index: 2;
    align-self: flex-start;
  }

  .player-counter-wrap {
    grid-area: player-counter-wrap;

    justify-self: right;
    padding-right: 5px;
    align-self: flex-start;
    margin-top: 10px;

    .counter.player-counter {
      display: flex;
      justify-content: stretch;
      align-items: center;
      .crow {
        display: flex;
        justify-content: stretch;
        align-items: center;
      }

      // @include media-breakpoint-down(xs) {
      //   // flex-direction: column;
      //   display: none;
      // }

      .available-money-wrap {
        word-break: keep-all;
        flex-wrap: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .question-wrap {
    grid-area: question-wrap;
    position: relative;
    text-align: center;
    // margin-top: 150px;

    @include media-breakpoint-down(md) {
      padding: 0 15px;
      align-self: end;
      background-color: white;
    }

    .curve-white-top {
      height: 50px;
      width: 100%;
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .joker-tutorial-wrap {
      display: grid;
      grid-template-columns: 1fr 50px;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      grid-gap: 5px;

      position: absolute;
      right: -20px;
      z-index: 6;

      @include media-breakpoint-down(md) {
        right: 5px;
      }

      .text {
        color: #958dea;
        font-size: 12px;
        text-align: right;
        font-weight: 600;

        @include media-breakpoint-down(md) {
          //color: black;
        }
      }

      .icon {
        width: 45px;
        height: 45px;
      }
    }

    .double-point-icon,
    .double-points-unavailable,
    .double-points-used {
      position: absolute;
      z-index: 1;
      right: 0;
      width: 70px;
      top: -10px;
      @include media-breakpoint-down(md) {
        right: 5px;
        width: 45px;
        top: -30px;
      }
    }

    .double-point-icon {
      cursor: pointer;
    }

    .joker-waiting {
      position: absolute;
      z-index: 1;
      right: 0;
      width: 30px;
      font-size: 23px;
      line-height: 40px;
      color: #a0a0a0;
    }

    .question {
      background: white;
      padding: 50px 30px;
      border-radius: 60px 75px 75px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 170px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 5px 5px;
        border-radius: 0;
        flex-direction: column;

        min-height: 50px;
      }

      .question-text {
        color: #999cff;
        text-align: center;
        font-weight: 600;
        font-size: 19px;
      }
    }

    .question-number {
      position: absolute;
      left: 11px;
      bottom: -31px;
      font-size: 21px;
      padding: 0px 0px;
      border-radius: 12px;
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;

      .icon {
        .fas {
          margin-right: 5px;
        }
      }

      .i-question {
        margin-right: 5px;
      }

      @include media-breakpoint-down(md) {
        // position: absolute;
        font-size: 15px;
        padding: 3px 10px;
        border-radius: 15px;
        color: white;

        right: 0;
        left: 0;
        // width: 45px;
        margin: 0 auto;
        text-align: center;
        top: -20px;
        position: relative;

        &.n80s {
          background-color: #3765f3;
        }

        &.n00s {
          background-color: #c30f55;
        }

        &.n90s {
          background-color: #04d7e9;
        }

        &.nmusic {
          background-color: #ffa22b;
        }

        &.nhollywood {
          background-color: #fd6522;
        }
      }
    }

    .answers {
      display: grid;
      grid-template-columns: repeat(2, minmax(50px, 1fr));
      grid-template-rows: repeat(2, minmax(85px, 1fr));
      justify-content: center;
      align-items: stretch;
      grid-column-gap: 10px;
      grid-row-gap: 15px;
      margin-top: 30px;
      text-transform: uppercase;
      font-family: "Alfa Slab One", cursive;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, minmax(55px, 1fr));
        margin-top: 15px;
        margin-bottom: 65px;

        grid-row-gap: 10px;
      }

      @include media-breakpoint-down(sm) {
        grid-template-rows: repeat(4, minmax(50px, 1fr));
      }

      .answer {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        position: relative;
        padding: 5px 15px;
        font-weight: normal;
        letter-spacing: 1px;
        font-size: 13px;
        border: 5px solid transparent;
        // opacity: 0.5;

        .last-score {
          position: absolute;
          left: 15px;
          top: -20px;
          transform: rotate(-15deg);

          .start-img {
            position: absolute;
            top: -1px;
            left: -9px;
            opacity: 0.5;
          }

          .text {
            font-size: 38px;
            color: #fedc25;
            text-shadow: -1px -1px 0 #fd5324, 3px -1px 0 #fd5324, -1px 5px 0 #fd5324, 2px 5px 0 #fd5324;
          }

          .symbol {
            font-size: 27px;
            color: #fedc25;
            text-shadow: 2px 2px #fd5324;
          }
        }

        &.n80s {
          background-color: #3765f380;
        }

        &.n00s {
          background-color: #a10f5280;
        }

        &.n90s {
          background-color: #05bfff80;
        }

        &.nmusic {
          background-color: #ff9a2a80;
        }

        &.nhollywood {
          background-color: #ff551d80;
        }

        &.correct-answer {
          background-color: #55c62c !important;
          border-color: #55c62c !important;
          opacity: 1;
        }

        .answer-mark {
          top: 0;
          bottom: 0;
          position: absolute;
          right: 0;
          /* background: gray; */
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          z-index: 2;

          .mark-correct,
          .mark-wrong {
            position: relative;
            right: 10px;
            border-radius: 50%;
            font-size: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            z-index: 2;
            background-color: white;

            @include media-breakpoint-down(sm) {
              // top: 2px;
              right: 3px;
            }
            @include media-breakpoint-down(md) {
              width: 35px;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .mark-wrong {
            color: #e90b18;
          }

          .mark-correct {
            color: #55c62c;

            img {
              filter: invert(36%) sepia(11%) saturate(3094%) hue-rotate(56deg) brightness(93%) contrast(89%);
            }

            // &.n80s {
            //     background-color: #3765f3;
            // }

            // &.n00s {
            //     background-color: #a10f52;
            // }

            // &.n90s {
            //     background-color: #05adff;
            // }

            // &.nmusic {
            //     background-color: #ff441b;
            // }

            // &.nhollywood {
            //     background-color: #ff551d;
            // }
          }
        }

        &.selected {
          // background-color: white;
          border-color: white;
          opacity: 1;

          // &.n80s {
          //     color: #3765f3;
          // }

          // &.n00s {
          //     color: #a10f52;
          // }

          // &.n90s {
          //     color: #05adff;
          // }

          // &.nmusic {
          //     color: #ff441b;
          // }

          // &.nhollywood {
          //     color: #ff551d;
          // }

          &.n80s {
            background-color: #3765f3;
          }

          &.n00s {
            background-color: #a10f52;
          }

          &.n90s {
            background-color: #05bfff;
          }

          &.nmusic {
            background-color: #ff9a2a;
          }

          &.nhollywood {
            background-color: #ff551d;
          }

          @include media-breakpoint-down(md) {
            &.n80s {
              border-color: #3765f3;
            }

            &.n00s {
              border-color: #a10f52;
            }

            &.n90s {
              border-color: #05bfff;
            }

            &.nmusic {
              border-color: #ff9a2a;
            }

            &.nhollywood {
              border-color: #ff551d;
            }

            // .correct-answer {
            //     color: white;
            // }
          }

          &.wrong-answer {
            background-color: #e90918 !important;
            border-color: #e90918 !important;
          }
        }

        &.selected.correct-answer {
          border: 3px solid white !important;
        }
      }
    }

    .timer {
      position: absolute;
      bottom: 8px;
      left: 7px;
      width: 50px;
      display: flex;
      height: 50px;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      padding: 3px;
      align-items: center;

      @include media-breakpoint-down(md) {
        bottom: auto;
        top: -48px;
        width: 50px;
        height: 50px;
      }

      .rotate {
        animation: rotate 2s linear infinite;
      }
      @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }

      .paused {
        animation-play-state: paused;
      }

      .t {
        font-weight: bolder;
        font-size: 20px;
        width: 100%;
      }

      .img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0;
        background-repeat: no-repeat;
        background-size: contain;
        // background-position: 0px 5px;

        &.n80s {
          background-image: url("/assets/images/timecircle/timecircle_80s.png");
        }

        &.n00s {
          background-image: url("/assets/images/timecircle/timecircle_00s.png");
        }

        &.n90s {
          background-image: url("/assets/images/timecircle/timecircle_90s.png");
        }

        &.nmusic {
          background-image: url("/assets/images/timecircle/timecircle_music.png");
        }

        &.nhollywood {
          background-image: url("/assets/images/timecircle/timecircle_hollywood.png");
        }
      }

      &.n80s {
        color: #3765f3;
      }

      &.n00s {
        color: #c30f55;
      }

      &.n90s {
        color: #04d7e9;
      }

      &.nmusic {
        color: #ffa22b;
      }

      &.nhollywood {
        color: #fd6522;
      }

      .timer-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 35px;
        color: #29f3ff;
        width: 100%;

        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 50px;
        }
      }
    }
  }

  .send-message-wrapper {
    grid-area: send-message;
    position: fixed;
  }

  .message-list {
    grid-area: message-list;

    align-self: stretch;
    margin-bottom: 86px;
    z-index: 6;

    @include media-breakpoint-down(md) {
      margin-bottom: 33px;
    }
  }
}
