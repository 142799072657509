@import "../FixBreakpoints.scss";

.game-end {
  .player-count-end {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .game-end-grid {
    display: grid;
    grid-template-columns: 1fr auto auto auto 1fr;
    grid-template-rows: 1fr 150px 240px;
    margin: 0 auto;
    grid-template-areas:
      ". secondPlace firstPlace thirdPlace messageList"
      ". stage stage stage messageList"
      "contentArea contentArea contentArea contentArea contentArea";
    height: 100vh;
    grid-gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: 960px),
    screen and (max-height: 650px) {
      grid-template-rows: 300px 150px auto;
    }

    @include media-breakpoint-down(md) {
      grid-template-rows: auto 1fr 100px 1fr;

      grid-template-areas:
        ". messageList messageList messageList messageList"
        ". secondPlace firstPlace thirdPlace ."
        ". stage stage stage ."
        "contentArea contentArea contentArea contentArea contentArea";
    }
  }

  button.link {
    border: none;
    background: transparent;
    min-width: 275px;
  }

  button:focus,
  input:focus {
    outline: none;
  }

  .game-end-char {
    position: absolute;
    width: 347px;
    bottom: 5px;
    left: 5px;
    z-index: 2;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .background-color {
    background: linear-gradient(to bottom, #2226b9, #1ca0e3, #1ca2e4, #1ca2e4);
  }

  .place {
    align-self: end;
    z-index: 4;

    .name {
      text-align: center;
      color: white;
      margin: 0 auto;
      font-weight: normal;
      margin-bottom: 5px;
      font-family: "Alfa Slab One", cursive;
      letter-spacing: 1px;
      text-transform: uppercase;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .score {
      text-align: center;
      color: white;
      font-weight: normal;
      margin-top: 10px;
      font-family: "Alfa Slab One", cursive;
      letter-spacing: 1px;
      margin-top: 50px;
      font-size: 20px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .first-place {
    grid-area: firstPlace;

    .score {
      top: 80px;
      position: relative;
      z-index: 6;

      &.n80s {
        color: #3765f3;
      }

      &.n00s {
        color: #c30f55;
      }

      &.n90s {
        color: #05bfff;
      }

      &.nmusic {
        color: #ffa22b;
      }

      &.nhollywood {
        color: #fd6522;
      }
    }

    img {
      position: relative;
      top: 75px;

      @include media-breakpoint-down(md) {
        top: 21px;
      }
    }

    .name {
      position: relative;
      top: 50px;
      font-size: 21px;
    }
  }

  .second-place {
    grid-area: secondPlace;
  }

  .third-place {
    grid-area: thirdPlace;
  }

  .second-place,
  .third-place {
    .score {
      &.n80s {
        text-shadow: 0px -2px 0px #4348dd;
      }

      &.n00s {
        text-shadow: 0px -2px 0px #881340;
      }

      &.n90s {
        text-shadow: 0px -2px 0px #0fa797;
      }

      &.nmusic {
        text-shadow: 0px -2px 0px #f88f2a;
      }

      &.nhollywood {
        text-shadow: 0px -2px 0px #f88f2a;
      }
    }
  }

  .stage {
    grid-area: stage;
    z-index: 0;
    position: relative;
    top: -55px;

    .podium {
      position: absolute;
      bottom: 0;
      left: 49%;
    }

    .img {
      position: relative;
      margin: 0 auto;
      left: -50%;
      width: 785px;
      height: 445px;
      background-image: url("/assets/images/podium_large.png");
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: -16px;
      pointer-events: none;

      @include media-breakpoint-down(md) {
        background-image: url("/assets/images/podium.png");
        width: 100vw;
        max-width: 500px;
        bottom: -194px;
        background-position: center;
      }
    }

    .mobile-score {
      display: none;
      font-family: "Alfa Slab One", cursive;
      letter-spacing: 1px;
      font-weight: normal;

      @include media-breakpoint-down(md) {
        display: block;
        display: block;
        margin-top: 166px;
      }

      .col-4 {
        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .name {
        // font-weight: bold;
        color: #1b6ab3;
        border-radius: 20px;
        padding: 5px;

        &.first {
          background-color: #ffdc00;
        }

        &.second {
          background-color: #87deff;
        }

        &.third {
          background-color: #ff9629;
        }
      }

      .score {
        position: absolute;
        right: 5px;
        top: -12px;
        background: #1f3fb8;
        color: white;
        padding: 0px 10px;
        border-radius: 15px;
        font-size: 12px;
        // font-weight: 600;
      }
    }
  }

  .message-list {
    grid-area: messageList;
    z-index: 6;

    @include media-breakpoint-down(md) {
      max-height: 99px;
    }

    .chat-text-area {
      max-height: 100%;

      @include media-breakpoint-down(md) {
        max-height: 65vh;
      }
    }
  }

  .score-wrap {
    height: 100%;
    width: 265px;
    margin: 0 auto;
    align-items: stretch;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    top: 0;

    @media screen and (max-height: 650px) {
      justify-content: flex-end;
      top: -5px;
    }

    @include media-breakpoint-down(md) {
      top: 120px;
      justify-content: flex-start;
      height: 0;
      overflow: visible;
    }

    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        margin-top: 0px;

        .curve-white-top {
          height: 160%;
        }
      }

      .text-or {
        text-align: center;
        color: white;
        font-weight: normal;
        margin-top: 10px;
        font-family: "Alfa Slab One", cursive;
        letter-spacing: 1px;
        font-size: 18px;

        &.n80s {
          color: #3765f3;
        }

        &.n00s {
          color: #c30f55;
        }

        &.n90s {
          color: #05bfff;
        }

        &.nmusic {
          color: #ffa22b;
        }

        &.nhollywood {
          color: #fd6522;
        }
      }

      .singleplayer-btn {
        color: white;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        text-transform: uppercase;
        height: 50px;
        position: relative;
        font-family: "Alfa Slab One", cursive;
        letter-spacing: 1px;
        font-weight: normal;

        img {
          //position: absolute;
          //left: 15px;
          filter: brightness(0) invert(1);
          rotate: 90deg;
        }

        .text {
          font-size: 14px;
          margin-left: 25px;
        }

        &.n80s {
          background-color: #4348dd;
        }

        &.n00s {
          background-color: #a10f52;
        }

        &.n90s {
          background-color: #05bfff;
        }

        &.nmusic {
          background-color: #ff9a2a;
        }

        &.nhollywood {
          background-color: #ff551d;
        }

        .up-img {
          background-image: url("../../assets/images/up.png");
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 16px;
        }
      }

      .quit-img {
        background-image: url("../../assets/images/quit.svg");
        height: 75%;
        width: 100%;
        background-repeat: no-repeat;
        margin-left: 135px;

        @media (max-width: $smallMobile) {
          margin-left: 120px;
        }

      }


      .lobby-btn {
        color: white;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        text-transform: uppercase;
        height: 50px;
        position: relative;
        font-family: "Alfa Slab One", cursive;
        letter-spacing: 1px;
        font-weight: normal;

        img {
          //position: absolute;
          //left: 15px;
          filter: brightness(0) invert(1);
          rotate: 180deg;
        }

        .text {
          font-size: 14px;
          margin-left: 25px;
        }

        &.n80s {
          background-color: #4348dd;
        }

        &.n00s {
          background-color: #a10f52;
        }

        &.n90s {
          background-color: #05bfff;
        }

        &.nmusic {
          background-color: #ff9a2a;
        }

        &.nhollywood {
          background-color: #ff551d;
        }
      }
    }

    /* Modal Overlay */
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    /* Modal Container */
    .modal-pop {
      background: #000000d4;
      border-radius: 8px;
      box-shadow: 0 5px 15px #0000004d;
      padding: 20px;
      width: 500px;
      height: 300px;
      z-index: 9;
      left: auto;
      top: auto;
      display: flex;
      align-items: center;
    }

    /* Modal Content */
    .modal-content-pop {
      .text {
        color: white;
        font-weight: 800;
        font-size: 24px;
      }
    }

    .modal-buttons {
      display: flex;
      margin-top: 20px;
      flex-wrap: nowrap;
      justify-content: space-around;
      .text-btn {
        color: white;
        font-weight: 800;
        margin: 25px;
      }
    }

  }

  /* Disable clicking outside the modal */
  .modal-overlay {
    pointer-events: none;
  }

  .modal-pop {
    pointer-events: auto;
  }

  .score-content {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #4346f1;
    padding-top: 3px;

    &.winning {
      border-top: 0px solid #4346f1;

      // h2 {
      //     font-size: 15px;
      // }
      // .score {
      //     font-size: 15px;
      // }
    }

    &.n80s {
      border-color: #4348dd;
    }

    &.n00s {
      border-color: #a10f52;
    }

    &.n90s {
      border-color: #05adff;
    }

    &.nmusic {
      border-color: #ff9a2a;
    }

    &.nhollywood {
      border-color: #ff551d;
    }

    h2,
    .score {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 900;
      margin: 0;

      &.n80s {
        color: #1f3fb8;
      }

      &.n00s {
        color: #a10f52;
      }

      &.n90s {
        color: #05adff;
      }

      &.nmusic {
        color: #ff9a2a;
      }

      &.nhollywood {
        color: #ff551d;
      }
    }

    .score {
      font-size: 15px;
      font-family: "Alfa Slab One", cursive;
      letter-spacing: 1px;
      font-weight: normal;
    }
  }
}

.white-area {
  grid-area: contentArea;

  grid-row: 3 / span 2;
  margin-top: -100px;

  @include media-breakpoint-down(md) {
    margin-top: 35px;
    min-height: 270px;
  }

  .curve-white-top {
    height: 100%;
  }
}


@media (min-width: 769px) {
  .display-none {
    display: none !important;
  }

  .button-row {
    display: block !important;
  }
}